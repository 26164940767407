<template>
  <div v-if="getProgress < 100 && getProgress !== null" class="progressContent">
    <b-progress
      :value="getProgress"
      :max="100"
      show-progress
      animated
    ></b-progress>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getProgress'])
  }
};
</script>

<style lang="scss">
.progressContent {
  width: 95%;
  margin: auto;
  margin-bottom: 5px;
  .progress-bar {
    background-color: #4d4bac;
  }
  .progress-bar-striped {
    background-image: linear-gradient(
      45deg,
      rgb(141 140 183) 25%,
      transparent 25%,
      transparent 50%,
      rgb(141 140 183) 50%,
      rgb(141 140 183) 75%,
      transparent 75%,
      transparent
    );
  }
}
</style>
